











































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Shop } from '@/api'
import { UserModule } from '@/store/modules'

@Component
export default class RedeemCoupon extends Vue {
  @UserModule.Action getUser

  couponCollected = {}
  coupon = ''
  couponInfo = {}
  isCouponChecked = false
  isCouponValid = false
  couponErrorMessage = null

  @Watch('coupon')
  onCouponChanged() {
    this.isCouponChecked = false
    this.isCouponValid = false
  }

  get alertVariant() {
    return this.isCouponValid ? 'success' : 'danger'
  }

  async redeemCoupon() {
    const redeem = await Shop.redeemCoupon(this.coupon)

    this.isCouponChecked = true

    if (redeem.status === 400) {
      this.isCouponValid = false
      this.couponErrorMessage = redeem.detail

      return
    }

    this.couponCollected = redeem
    this.isCouponValid = true
    this.$root.$emit('refreshCouponStash')
    await this.getUser()

    setTimeout(() => {
      this.isCouponChecked = false
      this.coupon = ''
    }, 5000)
  }
}
